import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import pic from "../assets/images/level-of-detail.png";
import { logAnalytics } from "../firebase";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function LevelOfDetailPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.LEVELOFDETAIL}>
      <Seo title="Level of Detail | Robotic Imaging" />

      <header className="site__header"></header>

      <main className="content">
        <img src={pic} className="img-sm" alt="" />
        <div className="project-delivery">
          <h3>Level 200 Modeling:</h3>
          <ul>
            <li>
              <span className="lidar__listitem__header">
                Architectural –&nbsp;
              </span>
              LOD 200 will show thickness of walls, window sizes and shapes, and
              decorative interior/exterior elements will be represented as
              squared off “blocks” with no measurable detail (for example, crown
              moulding will not have any curves and will be represented as a
              solid rectangle at the widest part of the moulding
            </li>
            <li>
              <span className="lidar__listitem__header">
                Structural – &nbsp;
              </span>
              LOD 200 will show major structural elements such as beam, column,
              and girders. Braces, bridging and connection plates will not be
              shown.
            </li>
          </ul>
          <h3>Level 300 Modeling:</h3>
          <ul>
            <li>
              Parts necessary for coordination of the element with nearby or
              attached elements are modeled. These include items like supports
              and connections. The quantity size, shape, location and
              orientation of the elements. Model can be directly measured from
              without referring to non-modeled information such as dimension
              call-outs.
            </li>
            <li>
              <span className="lidar__listitem__header">
                Architectural –&nbsp;
              </span>
              LOD 300 will show recesses around windows, more blocking on
              decorative elements.
            </li>
            <li>
              <span className="lidar__listitem__header">
                Structural – &nbsp;
              </span>
              LOD 300 will include smaller structural members like column braces
              and bridging.
            </li>
          </ul>
          <h3>Level 350 Modeling:</h3>
          <ul>
            <li>
              <span className="lidar__listitem__header">
                Architectural –&nbsp;
              </span>
              LOD 350 will show in detail the existing shapes of the moulding,
              layers will be represented in the wall components, and items
              around openings like brick soldiers will be modeled.
            </li>
            <li>
              <span className="lidar__listitem__header">
                Structural – &nbsp;
              </span>
              LOD 350 will include base plate connections (no hardware).
            </li>
          </ul>
          <h3>Level 400 Modeling:</h3>
          <ul>
            <li>
              The Model element is graphically represented within the model as a
              specific system, object or assembly in terms of size, shape,
              location, quantity and orientation with detailing, fabrication,
              assembly and installation information.
            </li>
          </ul>
        </div>
        <Link
          className="btn btn--center"
          to="/rfp"
          onClick={() => logAnalytics("clicked_receive_quote")}
        >
          Digitize Your Project
        </Link>
      </main>
    </SiteWrapper>
  );
}
